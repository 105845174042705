<template>
  <v-container id="upgrade" fluid tag="section" class="text_google">
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <h2 class="h1 font-weight-light text_google">
              <v-icon large left>mdi-file-send</v-icon>เงื่อนไขในการรับย้าย
            </h2>
          </template>
          <v-card class="pa-2 ma-6">
            <v-card-title>
              <div class="font-weight-light v-size--x-large">
                <v-icon large left>mdi-account-details</v-icon> ข้อมูลเบื้องต้น
                รหัสรายการอ้างอิง {{ id_ref }}
              </div>
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="VideoDialog()">
                <v-icon large color="red">mdi-video</v-icon>
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col cols="12" sm="6">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <h2>
                    วิทยาลัย : {{ colleges_user.college_name
                    }}{{ user.frist_name }} {{ user.last_name }}
                  </h2>
                  <h3>รหัสวิทยาลัย : {{ colleges_user.college_code }}</h3>
                  <br />
               
                  <div
                    v-if="
                      conditions_transfers.id_ref === id_ref &&
                        conditions_transfers.status_confirm !== 'needless'
                    "
                  >
                    <v-btn
                      block
                      color="info"
                      dark
                      :href="'#/college/print_condition/?id_ref=' + id_ref"
                      target="_blank"
                    >
                      <v-icon>mdi-printer</v-icon>
                      <span>พิมพ์แบบรายงานเงื่อนไขสาขาวิชาเอก</span>
                    </v-btn>
                    <div v-if="conditions_transfers.documentsLinkfile">                
                        
                        <v-btn
                        block
                        class="mt-1"
                        dark
                        color="teal"
                      @click="viewDocument(conditions_transfers.documentsLinkfile)"
                      >
                        <v-icon dark>
                          mdi-printer
                        </v-icon>
                        พิมพ์ไฟล์แนบ
                      </v-btn>
                      <div
                        v-if="conditions_transfers.status_confirm === 'cc'"
                        class="text-center pa-2"
                      >
                        <v-chip color="red" dark>
                          <v-icon>mdi-information</v-icon>
                          ขณะนี้รอให้ทางส่วนกลางตรวจสอบการแจ้งยกเลิก</v-chip
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          block
                          color="warning"
                          class="mt-1"
                          @click="cancelPDF()"
                        >
                          <v-icon>mdi-close</v-icon> ยกเลิกไฟล์แนบ
                        </v-btn>
                      </div>
                    </div>
                    <div v-else>
                      <v-form
                        ref="Updateconditions_transferform"
                        lazy-validation
                        v-if="
                          period_collegeFiles.period_college_enable_file ==='1'
                        "
                      >
                        แนบไฟล์เงือนไขสาขาวิชา ที่ได้รับการลงลายมือชื่อรับรอง
                        <v-file-input
                          v-model="documentsLinkfiles"
                          accept=".pdf"
                          name="documentsLinkfiles"
                          color="deep-purple accent-4"
                          counter
                          label="ไฟล์ .pdf"
                          placeholder="เลือกแนบไฟล์ PDF"
                          prepend-icon="mdi-paperclip"
                          outlined
                          rounded
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                        <v-btn
                          rounded
                          color="green"
                          @click="UpdateFileCondition()"
                        >
                          <v-icon>mdi-content-save</v-icon> บันทึกไฟล์</v-btn
                        >
                      </v-form>
                    </div>
                  </div>
<!-- 
                  <div
                    v-if="
                      conditions_transfers.id_ref === id_ref &&
                        conditions_transfers.status_confirm === 'needless'
                    "
                  >
                    <div v-if="conditions_transfers.documentsLinkfile">
                      <v-btn
                        large
                        rounded
                        dark
                        color="teal"
                        :href="
                          '/HRvecfiles/' +
                            conditions_transfers.documentsLinkfile
                        "
                        target="_blank"
                      >
                        <v-icon dark>
                          mdi-printer
                        </v-icon>
                        พิมพ์ไฟล์แนบ
                      </v-btn>
                    </div>
                  </div> -->
                </v-alert>
              </v-col>
              <v-col cols="12" sm="6">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <h2>
                    ครั้งที่ :
                    {{ period_colleges.period_college_times }} ประจำปี :
                    {{ period_colleges.period_college_year }}
                  </h2>
                  <h3>เขียนที่ : {{ colleges_user.college_name }}</h3>
                  <h3>วันที่ยื่นคำร้อง : {{ date_today }}</h3>
                </v-alert>
                <div
                  class="text-center"
                  v-if="conditions_transfers.id_ref !== id_ref"
                >
                  <v-btn
                    large
                    rounded
                    color="warning"
                    @click="cancleCondition()"
                  >
                    ไม่ประสงค์แจ้งเงือนไขรับย้าย
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-form
            ref="addconditions_transferform"
            lazy-validation
            v-if="
              period_colleges.period_college_enable === '1' &&
                period_colleges.period_college_type === 'movement_college'
            "
          >
            <v-card class="pa-2 ma-2">
              <v-card-title>
                <div class="font-weight-light v-size--x-large">
                  <v-icon large left>mdi-flag-letiant</v-icon>
                  สาขาวิชาในการรับย้าย เรียงลำดับดังนี้
                </div>
              </v-card-title>
              <v-col cols="12" sm="12">
                <v-card class="pa-2 ma-2">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-card
                        class="px-5 py-3"
                        v-if="conditions_transfers.id_ref === id_ref"
                      >
                        <v-alert
                          border="left"
                          colored-border
                          color="green darken-1"
                          elevation="2"
                          type="info"
                        >
                          <table width="100%" align="center" class="table">
                            <tr>
                              <th
                                width="7%"
                                class="regular16 th"
                                align="center"
                              >
                                อันดับ
                              </th>
                              <th width="10%" class="regular16 " align="center">
                                คุณวุฒิ
                              </th>
                              <th width="25%" class="regular16 " align="left">
                                สาขาวิชา
                              </th>
                              <th
                                width="10%"
                                class="regular16 th"
                                align="center"
                              >
                                จำนวนรับ
                              </th>
                            </tr>
                          </table>

                          <table
                            width="100%"
                            align="center"
                            class="table"
                            v-for="(item, index) in conditions_branchs"
                            :key="item.id_ref"
                            small
                          >
                            <tr>
                              <td
                                width="7%"
                                class="regular12 th"
                                align="center"
                              >
                                {{ index + 1 }}
                              </td>
                              <td width="10%" class="regular12 " align="center">
                                {{ item.educational_level }}
                              </td>
                              <td width="25%" class="regular12 ">
                                {{ item.id_branch }} {{ item.name_branch }}-{{
                                  item.name_sub_branch
                                }}
                              </td>
                              <td
                                width="10%"
                                class="regular12 th"
                                align="center"
                              >
                                {{ item.quantity_n }}
                              </td>
                            </tr>
                          </table>
                        </v-alert>
                      </v-card>
                      <v-card class="px-5 py-3" v-else>
                        <v-btn
                          x-large
                          color="success"
                          dark
                          @click.stop="addconditions_branchForm()"
                        >
                          <v-icon>mdi-selection-multiple-marker</v-icon>
                          <span> เลือกสาขาวิชา</span>
                        </v-btn>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  รายการ
                                </th>
                                <th class="text-left">
                                  รหัสอ้างอิง
                                </th>
                                <th class="text-left">
                                  สาขาวิชา
                                </th>
                                <th class="text-left">
                                  วุฒิการศึกษา
                                </th>
                                <th class="text-left">
                                  ระดับการศึกษา
                                </th>
                                <th class="text-left">
                                  จำนวนรับย้าย
                                </th>
                                <th class="text-left">
                                  ดำเนินการ
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in conditions_branchs"
                                :key="item.name"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.id_ref }}</td>
                                <td>{{ item.name_branch }}</td>
                                <td>{{ item.name_sub_branch }}</td>
                                <td>{{ item.educational_level }}</td>
                                <td>{{ item.quantity_n }}</td>
                                <td>
                                  <v-icon
                                    color="red"
                                    @click.stop="
                                      conditions_branchDelete(
                                        item.id_ref,
                                        item.id_branch,
                                        item.con_id_branch_sub,
                                        item.educational_level
                                      )
                                    "
                                  >
                                    mdi-delete
                                  </v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <h4>*ลำดับรายการ 1 - 10 ไม่มีผลต่อการพิจารณาย้าย</h4>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-card>
          </v-form>

          <v-form v-else>
            <v-card class="pa-2 ma-2">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
              >
                <h1 class="text-center red--text">
                  ปิดรับการยืนเงื่อนไขการรับย้าย
                </h1>
              </v-alert>
            </v-card>
          </v-form>

          <v-card
            class="pa-1 d-flex justify-center"
            v-if="
              period_colleges.period_college_enable === '1' &&
                period_colleges.period_college_type === 'movement_college'
            "
          >
            <div>
              <v-row>
                <div class="text-center">
                  <h3 class="red--text">
                    * เมื่อกดปุ่มบันทึกและยืนยันข้อมูลแล้ว
                    ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ ได้
                    กรุณาตรวจสอบข้อมูลให้ถูกต้องการกดปุ่ม บันทึก
                  </h3>
                  <h2 class="success--text">
                    *
                    เมื่อกดปุ่มบันทึกแล้วจะปรากฎปุ่มสำหรับพิมพ์แบบเสนอเงือนไขการรับย้าย
                  </h2>
                </div>
              </v-row>
              <v-row v-if="conditions_transfers.status_confirm !== 'needless'">
                <v-col cols="12" align="center">
                  <v-btn
                    v-if="conditions_transfers.id_ref === id_ref"
                    x-large
                    color="info"
                    dark
                    class="ma-2"
                    :href="'#/college/print_condition/?id_ref=' + id_ref"
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon>
                    <span>พิมพ์แบบรายงานเงื่อนไขสาขาวิชาเอก</span>
                  </v-btn>

                  <v-btn
                    v-else-if="id_ref"
                    x-large
                    color="success"
                    dark
                    class="ma-2"
                    @click.stop="confirmsSubmit()"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    <span>บันทึก</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </base-material-card>

        <!--addconditions_branchdialog  -->
        <v-layout>
          <v-dialog
            v-model="addconditions_branchdialog"
            persistent
            max-width="90%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เลือกสาขาวิชา"
                class="px-5 py-3"
              >
                รหัสรายการอ้างอิง {{ id_ref }}
                <div class="text-right">
                  <v-btn
                    fab
                    text
                    color="warning"
                    large
                    @click.stop="addconditions_branchdialog = false"
                    ><v-icon dark>mdi-close</v-icon></v-btn
                  >
                </div>
              </base-material-card>
              <v-card-text>
                <v-form ref="addconditions_branchform" lazy-validation>
                  <div id="todo-list-example">
                    <ul>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th width="5%" class="text-center">
                                ที่
                              </th>
                              <th width="15%" class="text-center">
                                <v-icon>mdi-source-commit</v-icon> สาขาวิชาเอก
                              </th>
                              <th width="15%" class="text-center">
                                <v-icon>mdi-source-branch</v-icon>
                                สาขางานที่ประสงค์รับ
                              </th>
                              <th width="25%" class="text-center">
                                <v-icon>mdi-account-multiple-plus</v-icon>
                                จำนวนครูที่สถานศึกษาต้องการที่จะรับย้าย
                              </th>
                              <th width="10%" class="text-center">
                                <v-icon>mdi-school</v-icon> ระดับการศึกษา
                              </th>
                              <th width="20%" class="text-center">
                                <v-icon>mdi-school</v-icon> คุณลักษณะทางการศึกษา
                              </th>
                              <th width="20%" class="text-center">
                                ดำเนินการ
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="(todo, index) in conditions_branchs"
                              v-bind:key="todo.id_rp_sub"
                              v-bind:title="todo.name_branch"
                              class="tdh"
                            >
                              <td class="pa-1 text-center">{{ index + 1 }}</td>
                              <td class="pa-1">
                                <div v-if="todo.condStatus !== 'save'">
                                  <v-autocomplete
                                    :items="branch_s"
                                    item-text="name_branch"
                                    item-value="id_branch"
                                    outlined
                                    label="สาขาวิชาเอก :"
                                    request
                                    v-model="todo.id_branch"
                                    :rules="[v => !!v || '']"
                                    @change="
                                      branch_sub_dQueryAll(
                                        index,
                                        todo.id_branch
                                      )
                                    "
                                  ></v-autocomplete>
                                </div>
                                <div v-else>
                                  {{ todo.name_branch }}
                                </div>
                              </td>

                              <td class="pa-1">
                                <div v-if="todo.condStatus !== 'save'">
                                  <v-autocomplete
                                    :items="branch_sub_ds"
                                    item-text="name_sub_branch"
                                    item-value="id_branch_sub"
                                    outlined
                                    label="สาขางานวุฒิการศึกษาที่ประสงค์รับ : ระบุหรือไม่ระบุก็ได้"
                                    v-model="todo.con_id_branch_sub"
                                    :rules="[v => !!v || '']"
                                  ></v-autocomplete>
                                </div>
                                <div v-else>
                                  {{ todo.name_sub_branch }}
                                </div>
                              </td>
                              <td class="pa-1">
                                <div v-if="todo.condStatus !== 'save'">
                                  <v-text-field
                                    v-model="todo.quantity_n"
                                    type="number"
                                    outlined
                                    label="จำนวนครูที่สถานศึกษาต้องการที่จะรับย้าย :"
                                    request
                                    :rules="[v => !!v || '']"
                                  ></v-text-field>
                                </div>
                                <div v-else class="text-center">
                                  {{ todo.quantity_n }}
                                </div>
                              </td>
                              <td class="pa-1">
                                <div v-if="todo.condStatus !== 'save'">
                                  <v-select
                                    v-model="todo.educational_level"
                                    type="number"
                                    :items="education_s"
                                    item-text="title"
                                    item-value="title"
                                    outlined
                                    label="ระดับการศึกษา :"
                                    request
                                    :rules="[v => !!v || '']"
                                  ></v-select>
                                </div>
                                <div v-else class="text-center">
                                  {{ todo.educational_level }}
                                </div>
                              </td>
                              <td class="pa-1">
                                <div v-if="todo.condStatus !== 'save'">
                                  <v-textarea
                                    v-model="todo.con_datail_branch"
                                    outlined
                                    label="คุณลักษณะทางการศึกษา อื่น ๆ เพิ่มเติม :"
                                  ></v-textarea>
                                </div>
                                <div v-else class="text-center">
                                  {{ todo.con_datail_branch }}
                                </div>
                              </td>
                              <td class="pa-1">
                                <v-text-field
                                  disabled
                                  v-model="todo.condStatus"
                                  outlined
                                  :rules="[v => !!v || '']"
                                ></v-text-field>
                                <v-btn
                                  v-if="todo.condStatus !== 'save'"
                                  text
                                  rounded
                                  color="green"
                                  @click="
                                    addconditions_branchSubmit(
                                      todo.id_branch,
                                      todo.con_id_branch_sub,
                                      todo.quantity_n,
                                      todo.educational_level,
                                      todo.con_datail_branch
                                    )
                                  "
                                  ><v-icon large
                                    >mdi-content-save</v-icon
                                  ></v-btn
                                >

                                <v-btn
                                  v-if="todo.condStatus !== 'save'"
                                  text
                                  rounded
                                  color="red"
                                  v-on:click="
                                    conditions_branchs.splice(index, 1)
                                  "
                                  ><v-icon large
                                    >mdi-delete-circle</v-icon
                                  ></v-btn
                                >
                              </td>
                            </tr>
                            <tr>
                              <td colspan="7">
                                <div class="text-right">
                                  <v-btn
                                    rounded
                                    large
                                    color="green"
                                    @click="addNewTodo()"
                                  >
                                    <v-icon>mdi-file-plus</v-icon>
                                    เพิ่มเงือนไขรับย้าย</v-btn
                                  >
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </ul>
                  </div>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model deleteuserdialog -->
        <v-layout>
          <v-dialog
            v-model="deletconditions_branchdialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบรายการ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="deleteconditions_branchform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบ
                            <span>
                              {{ editconditions_branch.id_branch }}:{{
                                editconditions_branch.name_branch
                              }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="deletconditions_branchdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deleteconditions_branchsubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model confirm -->
        <v-layout>
          <v-dialog v-model="confirm_dialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="ยืนยันรายการ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-alert outlined type="error" prominent border="left">
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="confirm_dialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="primary"
                  @click.stop="addconditions_transferSubmit()"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- Video Dialog -->
        <v-layout>
          <v-dialog v-model="VideoDialogYoutube" persistent max-width="80%">
            <v-card>
              <div class="text-center">
                <v-btn text large rounded @click="VideoDialogYoutubeStop()"
                  ><v-icon dark>mdi-close</v-icon></v-btn
                >
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/iVZy90tWSZg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model cancleConditionDialog -->
        <v-layout>
          <v-dialog v-model="cancleConditionDialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="ไม่ประสงค์รับย้าย"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="cancleConditionDialogform" lazy-validation>
                      <v-container grid-list-md>
                        <v-flex md12>
                          <v-file-input
                            v-model="documentsLinkfiles"
                            accept=".pdf"
                            name="documentsLinkfiles"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ วาระการประชุม .pdf"
                            placeholder="เลือกแนบไฟล์ วาระการประชุม PDF"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :show-size="1000"
                            :rules="rules"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-flex>
                        <v-alert outlined type="error" prominent border="left">
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="cancleConditionDialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="primary"
                  @click.stop="cancleConditionDialogSubmit()"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>


            <!-- V-model pdfdocumentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfdocumentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
        </v-layout>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      el: "#todo-list-example",
      newTodoText: "",
      conditions_branchs: [],
      nextTodoId: 4,

      loading: true,
      ApiKey: "HRvec2021",
      colleges_user: [],
      colleges: {},
      personnel_search: {},
      show_personnel: [],
      branch_s: [],
      search: "",
      addconditions_branchdialog: false,
      deletconditions_branchdialog: false,
      Search_Personneldialog: false,
      VideoDialogYoutube: false,
      confirm_dialog: false,
      branchsdialog: false,
      cancleConditionDialog: false,
      user: {},
      conditions_branch: [],
      conditions_branchs: [],
      conditions_transfers: [],
      addconditions_branch: {},
      addconditions_transfer: {},
      editconditions_branch: [],

      prefectures: [],
      district: [],
      district_post_s: [],
      period_colleges: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      order_need: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      header_trans: [
        { text: "ลำดับ", align: "center", value: "sn" },
        { text: "รหัสอ้างอิง", align: "center", value: "id_ref" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "วุฒิการศึกษา", align: "left", value: "name_sub_branch" },
        { text: "ระดับการศึกษา", align: "center", value: "educational_level" },
        { text: "จำนวน", align: "center", value: "quantity_n" },
        { text: "ยกเลิก", align: "center", value: "action" }
      ],
      education_s: [
        {
          title: "ปริญญาตรี"
        },
        {
          title: "ปริญญาโท",
          faculty: ""
        },
        {
          title: "ปริญญาเอก"
        }
      ],
      status_condition: [{ text: "ยืนยัน", value: "confirm" }],

      isEditing: null,
      model: null,
      show1: false,
      pagination: {},
      personnel_marriages: [],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      period_college_enable: "1",
      period_college_enable_file: "1",
      branch_sub_ds: [],
      branchselect: {},
      countbranch: [],
      documentsLinkfiles: null,
      updateFileconditions: {},
      loading: true,
      dialogLoading: true,
      interval: {},
      period_collegeFiles: [],
      value: 0,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      conditions_transferCC: {},
      linealerts: {},
      pdf_files:[],
      pdfdocumentsLinkfileDialog:false,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  async mounted() {
    await this.sweetAlertLoading()
    await this.periodQuery();
    await this.timeCheck();
    await this.branchQuery();
    await this.colleges_userQuery();
    await this.conditions_transferQueryAll();
    await this.conditions_branchQueryAll();
    Swal.close();
  },

  methods: {
    async viewDocument(documentsLinkfile){
      this.pdf_files = documentsLinkfile;
      this.pdfdocumentsLinkfileDialog = true;
    },

    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();         
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },


    async periodQuery() {
      let result_period_college;
      let result_period_filecollege;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "401"
      });
      this.period_colleges = result_period_college.data;

      result_period_filecollege = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable_file: "1",
        period_college_id: "401"
      });
      this.period_collegeFiles = result_period_filecollege.data;

      if (
        this.period_colleges.period_college_enable != "1" &&
        this.period_collegeFiles.period_college_enable_file != "1"
      ) {
        Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      }
    },

    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(
        this.period_colleges.period_college_stop + " 16:30:00"
      );

      let dateFileStop = new Date(
        this.period_colleges.period_college_attactmentstop + " 16:30:00"
      );

      let dateNows = dateNow.getTime();
      let dateStops = dateStop.getTime();
      let dateFileStops = dateFileStop.getTime();

      this.interval = setInterval(async () => {
        if (dateNows > dateStops) {
          await this.periodUpdate();
          await this.periodQuery();        
        }

        if (dateNows > dateFileStops) {
          await this.periodFileUpdate();
          await this.periodQuery();
        }
      }, 3000);
    },

    async periodUpdate() {
      this.period_colleges.ApiKey = this.ApiKey;
      this.period_colleges.period_college_enable = "0";
      let result = await this.$http.post(
        "period_college.update.php",
        this.period_colleges
      ); 
    },

    async periodFileUpdate() {
      this.period_colleges.ApiKey = this.ApiKey;
      this.period_colleges.period_college_enable_file = "0";
      let result = await this.$http.post(
        "period_college.update.php",
        this.period_colleges
      );  
    },

    async branchQuery() {
      let result_branch;
      result_branch = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch_s = result_branch.data;
    },
    async cancelPDF() {
      Swal.fire({
        title: "ขอยกเลิกการแนบไฟล์",
        text: "เนื่องจากแนบไฟล์ผิดพลาด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.conditions_transferCC.ApiKey = this.ApiKey;
          this.conditions_transferCC.id_ref = this.id_ref;
          this.conditions_transferCC.status_confirm = "cc";

          let resultup = await this.$http.post(
            "conditions_transfer.update.php",
            this.conditions_transferCC
          );
          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });

            this.linealerts.ApiKey = this.ApiKey;
            this.linealerts.college_name = this.colleges_user.college_name;
            this.linealerts.details =
              "งานย้าย : วิทยาลัยขอยกเลิกไฟล์แนบแจ้งเงือนไขสาขาวิชา";
            this.linealerts.datetime = this.date_today;

            let result_line = await this.$http.post(
              "linenotify_hr.php",
              this.linealerts
            );

            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "conditions_transfer CC";
            this.data_syslog.page_log = "conditions_branch";
            this.data_syslog.table_log = "conditions_transfer.cc";
            this.data_syslog.detail_log = this.id_ref;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async cancleCondition() {
      this.cancleConditionDialog = true;
    },
    async VideoDialogYoutubeStop() {
      var videos = document.querySelectorAll("iframe, video");
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === "video") {
          video.pause();
        } else {
          var src = video.src;
          video.src = src;
        }
      });
      this.VideoDialogYoutube = false;
    },
    async VideoDialog() {
      this.VideoDialogYoutube = true;
    },
    async addNewTodo() {
      if (this.$refs.addconditions_branchform.validate()) {
        let result = await this.$http.post("conditions_branch.php", {
          ApiKey: this.ApiKey,
          id_ref: this.id_ref,
          countbranch: "OK"
        });
        this.countbranch = result.data;
        if (this.countbranch.countRefbranch >= 10) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ท่านได้ทำการบันทึกรายการครบ 10 ลำดับ";
          this.snackbar.show = true;
        } else {
          this.conditions_branchs.push({
            id: this.nextTodoId++,
            title: this.newTodoText
          });
          this.newTodoText = "";
        }
      }
    },

    async colleges_userQuery() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("admin.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.colleges_user = result.data;
    },

    async confirmsSubmit() {
      this.confirm_dialog = true;
    },

    async conditions_branchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_branch.php", {
          ApiKey: this.ApiKey,
          id_ref: this.id_ref
        })
        .finally(() => (this.loading = false));
      this.conditions_branchs = result.data;
    },

    async branch_sub_dQueryAll(index, id_branch) {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds = result.data;
    },

    async conditions_transferQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer.php", {
          ApiKey: this.ApiKey,
          id_ref: this.id_ref
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },

    //First >> Insert transference Location
    async addconditions_branchForm() {
      this.conditions_branchQueryAll();
      this.addconditions_branchdialog = true;
    },

    async addconditions_branchSubmit(
      id_branch,
      con_id_branch_sub,
      quantity_n,
      educational_level,
      con_datail_branch
    ) {
      this.addconditions_branch.ApiKey = this.ApiKey;
      this.addconditions_branch.id_ref = this.id_ref;
      this.addconditions_branch.college_code = this.colleges_user.college_code;
      this.addconditions_branch.id_branch = id_branch;
      this.addconditions_branch.con_id_branch_sub = con_id_branch_sub;
      this.addconditions_branch.con_datail_branch = con_datail_branch;
      this.addconditions_branch.educational_level = educational_level;
      this.addconditions_branch.quantity_n = quantity_n;
      this.addconditions_branch.condStatus = "save";

      let result = await this.$http.post(
        "conditions_branch.insert.php",
        this.addconditions_branch
      );

      if (result.data.status == true) {
        this.conditions_branch = result.data;
        this.snackbar.icon = "mdi-checkbox-marked-circle";
        this.snackbar.color = "success";
        this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
        this.snackbar.show = true;
        this.conditions_branchQueryAll();
      } else {
        this.snackbar.icon = "mdi-close-network";
        this.snackbar.color = "red";
        this.snackbar.text = "บันทึกข้อมูลผิดพลาด รายการลำดับ หรือ สาขาวิชาซ้ำ";
        this.snackbar.show = true;
        this.conditions_branchQueryAll();
      }
    },
    //Delete transference Location
    async conditions_branchDelete(
      id_ref,
      id_branch,
      con_id_branch_sub,
      educational_level
    ) {
      let result = await this.$http.post("conditions_branch.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref,
        id_branch: id_branch,
        con_id_branch_sub: con_id_branch_sub,
        educational_level: educational_level
      });
      this.editconditions_branch = result.data;
      this.deletconditions_branchdialog = true;
    },
    async deleteconditions_branchsubmit() {
      if (this.$refs.deleteconditions_branchform.validate()) {
        this.editconditions_branch.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "conditions_branch.delete.php",
          this.editconditions_branch
        );
        if (result.data.status == true) {
          this.conditions_branch = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_branchQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_branchQueryAll();
        }
        this.deletconditions_branchdialog = false;
      }
    },
    // Add conditions_transfer
    async addconditions_transferSubmit() {
      if (this.$refs.addconditions_transferform.validate()) {
        this.addconditions_transfer.ApiKey = this.ApiKey;
        this.addconditions_transfer.id_ref = this.id_ref;
        this.addconditions_transfer.time_s = this.period_colleges.period_college_times;
        this.addconditions_transfer.year_s = this.period_colleges.period_college_year;
        this.addconditions_transfer.college_code = this.colleges_user.college_code;
        this.addconditions_transfer.date_time = this.date_today;
        let result = await this.$http.post(
          "conditions_transfer.insert.php",
          this.addconditions_transfer
        );
        if (result.data.status == true) {
          this.conditions_transfers = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_transferQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.confirm_dialog = false;
      }
    },
    async UpdateFileCondition() {
      let result = "";
      let uploaded = null;
      this.updateFileconditions.ApiKey = this.ApiKey;
      this.updateFileconditions.id_ref = this.conditions_transfers.id_ref;
      if (this.$refs.Updateconditions_transferform.validate()) {
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename = this.conditions_transfers.id_ref + "condition.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.updateFileconditions.documentsLinkfile = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      }
      result = await this.$http.post(
        "conditions_transfer.update.php",
        this.updateFileconditions
      );
      if (result.data.status || uploaded) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.colleges_userQuery();
        await this.conditions_transferQueryAll();
        await this.conditions_branchQueryAll();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },

    async cancleConditionDialogSubmit() {
      let result = "";
      let uploaded = null;
      this.addconditions_transfer.ApiKey = this.ApiKey;
      this.addconditions_transfer.id_ref = this.id_ref;
      this.addconditions_transfer.time_s = this.period_colleges.period_college_times;
      this.addconditions_transfer.year_s = this.period_colleges.period_college_year;
      this.addconditions_transfer.status_confirm = "needless";
      this.addconditions_transfer.college_code = this.colleges_user.college_code;
      this.addconditions_transfer.date_time = this.date_today;

      if (this.$refs.cancleConditionDialogform.validate()) {
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename = this.id_ref + "conditionCan.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.addconditions_transfer.documentsLinkfile = filename;
            result = await this.$http.post(
              "conditions_transfer.insert.php",
              this.addconditions_transfer
            );
            if (result.data.status || uploaded) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              await this.colleges_userQuery();
              await this.conditions_transferQueryAll();
              await this.conditions_branchQueryAll();
            }
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        if (!uploaded) {
          Swal.fire({
            icon: "error",
            title: "กรุณาแนบไฟล์ให้ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.cancleConditionDialog = false;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    id_ref() {
      let id_ref_cr;
      id_ref_cr =
        this.period_collegeFiles.period_college_times +
        "" +
        this.period_collegeFiles.period_college_year +
        "" +
        this.colleges_user.user_name;
      return id_ref_cr;
    },

    date_today() {
      let monthNames = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;

      today = dd + " " + monthNames[parseInt(mm)] + " " + yyyy;
      return today;
    }
  },
  watch: {
    async conditions_br() {
      await this.conditions_transferQueryAll();
    }
  }
};
</script>
<style>
.textfeild {
  border: none;
  border-color: aliceblue;
}
.tdh {
  height: 5px;
}
</style>
